import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Dropdown, Icon, Input, Menu, Modal, Switch, Table, Typography} from "antd";

import moment from "moment";

import {animalTypeService} from "../../redux/services";

import DefaultTemplate from "../../templates/defaultTemplate";

import ModalCreate from "./create";
import ModalEdit from "./edit";
import ModalShow from "./show";
import ModalExport from "./export";

const config = {
    title: "Tipos de Animais",
    orderByField: "order",
    orderBySort: "asc",
    permissionPrefix: "animals",
};

class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            data: [],
            pagination: {
                current: 1,
                pageSize: 15,
                total: 0,
            },
            orderByField: config.orderByField,
            orderBySort: config.orderBySort,
            search: "",
            // Actions
            createModalVisible: false,
            editModalVisible: false,
            showModalVisible: false,
            exportModalVisible: false,
            activeLoadings: [],
            // Images
            imagePreviewVisible: false,
            imagePreviewImage: "",
        };
    }

    componentDidMount() {
        // Fecth all
        this.fetchGetAll();
    }

    menuMain = () => (
        <Menu className="actions-dropdown-menu">
            {this.props.permissions.includes(config.permissionPrefix + ".create") && <Menu.Item key="store">
                <a onClick={this.createOpen}>
                    <Icon type="plus"/>Cadastrar
                </a>
            </Menu.Item>}
            {this.props.permissions.includes(config.permissionPrefix + ".export") && <Menu.Item key="export">
                <a onClick={this.exportOpen}>
                    <Icon type="export"/>Exportar
                </a>
            </Menu.Item>}
        </Menu>
    );

    menu = (item) => (
        <Menu className="actions-dropdown-menu">
            {this.props.permissions.includes(config.permissionPrefix + ".show") && <Menu.Item key="show">
                <a onClick={() => this.showOpen(item)}>
                    <Icon type="file-text"/>Visualizar
                </a>
            </Menu.Item>}
            {this.props.permissions.includes(config.permissionPrefix + ".edit") && <Menu.Item key="edit">
                <a onClick={() => this.editOpen(item)}>
                    <Icon type="edit"/>Editar
                </a>
            </Menu.Item>}
            {this.props.permissions.includes(config.permissionPrefix + ".delete") && <Menu.Item key="delete">
                <a onClick={() => this.deleteConfirm(item)}>
                    <Icon type="delete"/>Excluir
                </a>
            </Menu.Item>}
        </Menu>
    );

    columns = () => {
        let columns = [
            {
                title: "ID",
                key: "id",
                dataIndex: "id",
                width: 90,
                sorter: true,
                fixed: "left",
            },
            {
                title: "",
                key: "file",
                dataIndex: "file_sizes.admin_listing",
                width: 70,
                sorter: false,
                className: "image disable-sort",
                render: (text, item) => {
                    if (!text) {
                        return null;
                    }

                    return (
                        <a onClick={() => this.onImagePreview(item.file)}>
                            <img src={text} style={{ maxHeight: "69px" }} />
                        </a>
                    )
                }
            },
            {
                title: "Nome",
                key: "name",
                dataIndex: "name",
                sorter: true,
            },
            {
                title: "Categoria Pai",
                key: "category",
                dataIndex: "category",
                sorter: true,
            },
            {
                title: "Ordem",
                key: "order",
                dataIndex: "order",
                width: 100,
                sorter: true,
                defaultSortOrder: "ascend",
            },
            {
                title: "Criação",
                key: "created_at",
                dataIndex: "created_at",
                width: 150,
                sorter: true,
                render: (text) => moment(text).format("DD/MM/YYYY HH:mm"),
            },
            {
                title: "Últ. modificação",
                key: "updated_at",
                dataIndex: "updated_at",
                width: 160,
                sorter: true,
                fixed: window.innerWidth > 991 ? "right" : false,
                render: (text) => moment(text).format("DD/MM/YYYY HH:mm"),
            },
        ];

        if (this.props.permissions.includes(config.permissionPrefix + ".edit")) {
            columns.push({
                title: "Ativo",
                key: "is_active",
                dataIndex: "is_active",
                width: 78,
                sorter: true,
                className: "active",
                fixed: window.innerWidth > 767 ? "right" : false,
                render: (text, item) => <Switch checked={item.is_active}
                                                loading={this.state.activeLoadings.indexOf(item.id) !== -1}
                                                onChange={(checked) => this.activateDeactivate(item, checked)}/>
            });
        }

        if (this.props.permissions.includes(config.permissionPrefix + ".show") || this.props.permissions.includes(config.permissionPrefix + ".edit") || this.props.permissions.includes(config.permissionPrefix + ".delete")) {
            columns.push({
                title: "Ações",
                key: "actions",
                width: 78,
                className: "actions disable-sort",
                fixed: window.innerWidth > 767 ? "right" : false,
                render: (text, item) => (
                    <Dropdown overlay={this.menu(item)} className="actions-dropdown" placement="bottomRight"
                              trigger={["click"]}>
                        <Button icon="more"/>
                    </Dropdown>
                ),
            });
        }

        return columns;
    };

    fetchGetAll = () => {
        const {pagination, orderByField, orderBySort, search} = this.state;

        this.setState({
            isLoading: true,
        });

        animalTypeService.getAll({
            page: pagination.current,
            limit: pagination.pageSize,
            orderBy: `${orderByField}:${orderBySort}`,
            search: search,
        })
            .then((response) => {
                const data = response.data.data.map(({
                                                         id,
                                                         file,
                                                         file_sizes,
                                                         name,
                                                         parent,
                                                         order,
                                                         created_at,
                                                         updated_at,
                                                         is_active
                                                     }) => {
                    return {
                        id,
                        file,
                        file_sizes,
                        name,
                        category: parent ? parent.name : '',
                        order,
                        parent,
                        created_at,
                        updated_at,
                        is_active
                    }
                });

                this.setState(state => ({
                    isLoading: false,
                    data,
                    pagination: {
                        ...state.pagination,
                        total: response.data.meta.total,
                    },
                }));
            })
            .catch((data) => {
                this.setState({
                    isLoading: false,
                });

                Modal.error({
                    title: "Ocorreu um erro!",
                    content: data.error_message,
                });
            });
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.setState(state => ({
            pagination: {
                ...state.pagination,
                current: pagination.current,
                pageSize: pagination.pageSize,
            },
            orderByField: sorter.field ? sorter.field : config.orderByField,
            orderBySort: sorter.order === "descend" ? "desc" : "asc",
        }), () => {
            this.fetchGetAll();
        });
    };

    onSearch = (value) => {
        this.setState({
            search: value,
        }, () => {
            this.fetchGetAll();
        });
    };

    onSearchChange = (e) => {
        // If it does not have type then it's cleaning
        if (!e["type"]) {
            const {search} = this.state;

            this.setState({
                search: e.target.value,
            }, () => {
                if (search) {
                    this.fetchGetAll();
                }
            });
        }
    };

    /**
     * Create
     */
    createOpen = () => {
        this.setState({createModalVisible: true});

        // On open screen
        this.createScreen.onOpen();
    };

    createOnClose = () => {
        this.setState({createModalVisible: false});
    };

    createOnComplete = () => {
        this.setState({createModalVisible: false});

        // Fecth all
        this.fetchGetAll();
    };

    /**
     * Edit
     *
     * @param id
     */
    editOpen = ({id}) => {
        this.setState({editModalVisible: true});

        // On open screen
        this.editScreen.onOpen(id);
    };

    editOnClose = () => {
        this.setState({editModalVisible: false});
    };

    editOnComplete = () => {
        this.setState({editModalVisible: false});

        // Fecth all
        this.fetchGetAll();
    };

    /**
     * Show
     *
     * @param id
     */
    showOpen = ({id}) => {
        this.setState({showModalVisible: true});

        // On open screen
        this.showScreen.onOpen(id);
    };

    showOnClose = () => {
        this.setState({showModalVisible: false});
    };

    /**
     * Delete
     *
     * @param id
     */
    deleteConfirm = ({id}) => {
        Modal.confirm({
            title: "Confirmar exclusão!",
            content: "Tem certeza de que deseja excluir este registro?",
            okText: "Excluir",
            onOk: () => {
                return this.deleteConfirmed(id);
            }
        });
    };

    deleteConfirmed = (id) => {
        return animalTypeService.destroy({id})
            .then((response) => {
                // Fecth all
                this.fetchGetAll();
            })
            .catch((data) => {
                Modal.error({
                    title: "Ocorreu um erro!",
                    content: data.error_message,
                });
            });
    };

    /**
     * Export
     */
    exportOpen = () => {
        this.setState({exportModalVisible: true});
    };

    exportOnClose = () => {
        this.setState({exportModalVisible: false});
    };

    exportOnComplete = () => {
        this.setState({exportModalVisible: false});
    };

    /**
     * Active/Desactive
     *
     * @param {number} id
     * @param {boolean} activate
     */
    activateDeactivate = ({id}, activate) => {
        const {activeLoadings} = this.state;

        if (activeLoadings.indexOf(id) === -1) {
            activeLoadings.push(id);
        }

        this.setState({
            activeLoadings: activeLoadings,
        });

        animalTypeService.edit({id, is_active: activate})
            .then((response) => {
                const newData = [...this.state.data];
                const index = newData.findIndex(item => id === item.id);

                if (index !== -1) {
                    const item = newData[index];

                    newData.splice(index, 1, {
                        ...item,
                        is_active: response.data.data.is_active,
                    });

                    this.setState({
                        data: newData,
                    });
                }
            })
            .catch((data) => {
                Modal.error({
                    title: "Ocorreu um erro!",
                    content: data.error_message,
                });
            }).finally(() => {
            const {activeLoadings} = this.state;
            const index = activeLoadings.indexOf(id);

            if (index !== -1) {
                activeLoadings.splice(index, 1);

                this.setState({
                    activeLoadings: activeLoadings,
                });
            }
        });
    };

    /**
     * Image preview
     */
    onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

    onImagePreview = (url) => {
        this.setState({
            imagePreviewImage: url,
            imagePreviewVisible: true,
        });
    };

    render() {
        return (
            <DefaultTemplate>
                <div className="page-content" key="1">
                    <h1 className="page-title">{config.title}</h1>
                    <div className="page-listing-header">
                        <div className="search">
                            <Input.Search
                                placeholder="Pesquisar"
                                onSearch={this.onSearch}
                                onChange={this.onSearchChange}
                                enterButton
                                allowClear
                            />
                        </div>
                        {(this.props.permissions.includes(config.permissionPrefix + ".create") || this.props.permissions.includes(config.permissionPrefix + ".export")) && (
                            <div className="actions">
                                <Dropdown className="actions-dropdown" placement="bottomRight" trigger={["click"]}
                                          overlay={this.menuMain}>
                                    <Button type="primary" icon="more"/>
                                </Dropdown>
                            </div>
                        )}
                    </div>
                    {this.state.search && <Typography.Paragraph>Buscando por <Typography.Text
                        mark>{this.state.search}</Typography.Text></Typography.Paragraph>}
                    <Table
                        className="page-listing-body"
                        columns={this.columns()}
                        rowKey="id"
                        bordered
                        scroll={{x: 1000}}
                        dataSource={this.state.data}
                        pagination={{...this.state.pagination, showSizeChanger: false, showQuickJumper: false, hideOnSinglePage: true}}
                        loading={this.state.isLoading}
                        onChange={this.handleTableChange}
                        footer={() => `Total ${this.state.pagination.total} de registros encontrados.`}
                    />
                </div>
                <ModalCreate
                    wrappedComponentRef={el => this.createScreen = el}
                    visible={this.state.createModalVisible}
                    onComplete={this.createOnComplete}
                    onClose={this.createOnClose}
                    list={this.state.data}
                />
                <ModalEdit
                    wrappedComponentRef={el => this.editScreen = el}
                    visible={this.state.editModalVisible}
                    onComplete={this.editOnComplete}
                    onClose={this.editOnClose}
                    list={this.state.data}
                />
                <ModalShow
                    ref={el => this.showScreen = el}
                    visible={this.state.showModalVisible}
                    onClose={this.showOnClose}
                    list={this.state.data}
                />
                <ModalExport
                    visible={this.state.exportModalVisible}
                    onComplete={this.exportOnComplete}
                    onClose={this.exportOnClose}
                />
                <Modal className="modal-image" visible={this.state.imagePreviewVisible} footer={null}
                       destroyOnClose={true} onCancel={this.onImagePreviewClose}>
                    <img src={this.state.imagePreviewImage}/>
                </Modal>
            </DefaultTemplate>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        permissions: state.auth.userData.permissions,
    };
};

export default connect(mapStateToProps)(Index);
