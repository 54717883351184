import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Col, Drawer, Form, Icon, Input, message, Modal, Row, Spin, Switch, Upload } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "../../config/general";

import { FORM_VALIDATION_MESSAGES } from "../../config/lang";

import { sponsoredPostService } from "../../redux/services";
import TextEditor from "../../components/TextEditor";

const FormItem = Form.Item;

const config = {
	images: {
		images: {
			maxSize   : 4,
			maxFiles  : 1,
			extensions: ["jpg", "png"],
			type      : ["image/jpeg", "image/png"],
		}
	},
};

class Edit extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			text 			   : "",
			link			   : "",
			buttonText		   : "",
			isLoading          : true,
			isSending          : false,
			id                 : 0,
			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			imageList          : {
				images: [],
			},
			imageListDeleted   : {
				images: [],
			},
			imageAdsList          : {
				images: [],
			},
			imageAdsListDeleted   : {
				images: [],
			},
		};
	}

	fieldOptions = {
		message     : {
			label    : "Texto",
			decorator: {},
		},
		link     : {
			label    : "Link",
			decorator: {
				initialValue: "",
				rules: [
					{required: false},
				],
			},
		},
		buttonText : {
			label    : "Texto botao",
			decorator: {
				initialValue: "",
				rules: [
					{required: false},
				],
			},
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
		});

		sponsoredPostService.show({id})
		.then((response) => {
			let item = response.data.data;
			this.setState({
				isLoading: false,
			});

			let imagesAdsList = {
				images: [],
			};

			this.setState({
				imageAdsList: imagesAdsList,
			});

			// Fill form
			this.fillForm(item);
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	fillForm = (data) => {
		this.props.form.setFieldsValue({
			message     : data.message,
			link     : data.link,
			buttonText: data.buttonText
		});

		let imageList = {
			images: [],
		};

		if( data.images?.length )
			{
				data.images.forEach(image => {
					imageList.images.push({
						uid   : image.id,
						name  : image.file.split("/").pop(),
						status: "done",
						url   : image.file,
						// Has id, is api image
						id    : image.id,
					});
				});
			}

		this.setState({
			imageList
		});
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			imageList       : {
				images: [],
			},
			imageListDeleted: {
				images: [],
			},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				const {imageList, imageListDeleted, imageAdsList} = this.state;

				this.setState({
					isSending: true,
				});

				// File
				values.images = imageList.images?.length ? imageList.images : null;
				values.delete_images = imageListDeleted.images?.length ? imageListDeleted.images : null;

				values.type = 'sponsored'
				values.link = this.state.link
				values.buttonText = this.state.buttonText
				values.id = this.state.id

				const id = this.state.id

				sponsoredPostService.edit(values)
					.then((response) => {
						this.setState({
							isSending: false,
						});

						// Reset fields
						this.resetFields();

						// Success message
						message.success("Registro cadastrado com sucesso.");

						// Callback
						this.props.onComplete();
					})
					.catch((data) => {
						this.setState({
							isSending: false,
						});

						// if validation error
						if( data.error_type === API_ERRO_TYPE_VALIDATION )
						{
							let hasFieldsErrors = false;

							for( let key in data.error_errors )
							{
								if( data.error_errors[key] && this.fieldOptions[key] )
								{
									this.props.form.setFields({
										[key]: {
											value : values[key],
											errors: [new Error(data.error_errors[key])],
										}
									});

									hasFieldsErrors = true;
								}
							}

							if( !hasFieldsErrors )
							{
								Modal.error({
									title  : "Ocorreu um erro!",
									content: data.error_message,
								});
							}
						}
						else
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					});

			}
		});
	};

	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (type, file) => {
		this.setState({
			imagePreviewImage  : file.url,
			imagePreviewVisible: true,
		});
	};

	onImageRemove = (type, file) => {
		let imagesNew           = [...this.state.imageList[type]];
		let imageListDeletedNew = [...this.state.imageListDeleted[type]];

		const index = imagesNew.findIndex(item => file.uid === item.uid);

		if( index !== -1 )
		{
			imagesNew.splice(index, 1);

			// Has id
			if( file.id )
			{
				imageListDeletedNew.push(file.id);
			}

			this.setState(state => ({
				imageList       : {
					...state.imageList,
					[type]: imagesNew,
				},
				imageListDeleted: {
					...state.imageListDeleted,
					[type]: imageListDeletedNew,
				},
			}));
		}
	};

	onImageAdsPreviewClose = () => this.setState({imageAdsPreviewVisible: false});

	onImageAdsPreview = (type, file) => {
		this.setState({
			imageAdsPreviewImage  : file.url,
			imageAdsPreviewVisible: true,
		});
	};

	onImageAdsRemove = (type, file) => {
		let imagesAdsNew           = [...this.state.imageAdsList[type]];
		let imageAdsListDeletedNew = [...this.state.imageAdsListDeleted[type]];

		const index = imagesAdsNew.findIndex(item => file.uid === item.uid);

		if( index !== -1 )
		{
			imagesAdsNew.splice(index, 1);

			// Has id
			if( file.id )
			{
				imageAdsListDeletedNew.push(file.id);
			}

			this.setState(state => ({
				imageAdsList       : {
					...state.imageAdsList,
					[type]: imagesAdsNew,
				},
				imageAdsListDeleted: {
					...state.imageAdsListDeleted,
					[type]: imageAdsListDeletedNew,
				},
			}));
		}
	};

	renderImages(type) {
		const imageList   = this.state.imageList[type];
		const imageConfig = config.images[type];

		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<div className="media-images-wrap">
				<Upload
					accept={`.${imageConfig.extensions.join(",.")}`}
					listType="picture-card"
					className="media-images-uploader"
					fileList={imageList}
					onPreview={(file) => this.onImagePreview(type, file)}
					onRemove={(file) => this.onImageRemove(type, file)}
					beforeUpload={(file) => {
						if( !imageConfig.type.includes(file.type) )
						{
							message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

							return false;
						}

						const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

						if( !isValidSize )
						{
							message.error(`A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`);

							return false;
						}

						let reader    = new FileReader();
						reader.onload = (e) => {
							let imagesNew = [...this.state.imageList[type]];

							if( imagesNew?.length < imageConfig.maxFiles )
							{
								// Base64
								file.url = e.target.result;

								imagesNew.push(file);

								this.setState(state => ({
									imageList: {
										...state.imageList,
										[type]: imagesNew,
									},
								}));
							}
						};

						reader.readAsDataURL(file);

						return false;
					}}>
					{imageList?.length >= imageConfig.maxFiles ? null : uploadButton}
				</Upload>
			</div>
		);
	}

	render() {
		const {visible, form}                                                    = this.props;
		const {id, isLoading, isSending, imagePreviewVisible, imagePreviewImage, imageAdsPreviewVisible, imageAdsPreviewImage} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={500}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading || isSending} />
						<div className="ant-drawer-title">{`Editar registro [${id}]`}</div>
						<Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label="Imagem">
								{this.renderImages("images")}
							</FormItem>
							<FormItem label={this.fieldOptions.message.label} hasFeedback>
								{getFieldDecorator("message", this.fieldOptions.message.decorator)(
									<TextEditor/>
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.link.label} hasFeedback>
								{getFieldDecorator("link", this.fieldOptions.link.decorator)(
									<Input
										value={this.state.link}
									onChange={(e) => {
										this.setState({
											link: e.target.value
										})
									}} />
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.buttonText.label} hasFeedback>
								{getFieldDecorator("buttonText", this.fieldOptions.buttonText.decorator)(
									<Input
										value={this.state.buttonText}
									onChange={(e) => {
										this.setState({
											buttonText: e.target.value
										})
									}} />
								)}
							</FormItem>
						</div>
					)}
				</Form>
				<Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					<img src={imagePreviewImage} />
				</Modal>
				<Modal className="modal-image" visible={imageAdsPreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImageAdsPreviewClose}>
					<img src={imageAdsPreviewImage} />
				</Modal>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Edit);
