import {Component} from "react";
import {Form} from "antd";
import ReactHlsPlayer from "react-hls-player";

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: props.url,
        }
    }

    render() {
        const {
            url
        } = this.state;
        return <ReactHlsPlayer
            src={url}
            autoPlay={false}
            controls={true}
            width="90%"
            height="auto"
        />
    }
}

export default Form.create()(VideoPlayer);
